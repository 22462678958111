import { Pannellum, PannellumVideo } from "pannellum-react";

const Panorama = (props) => {
  return (
    <div style={{height: "100vh", width: "100vw"}}>
      <Pannellum
        width="100%"
        height="100%"
        image={props.link}
        pitch={0}
        yaw={0}
        hfov={110}
        autoLoad
        showControls={false}
      ></Pannellum>
    </div>
  );
};

export default Panorama;
