import logo from "./logo.svg";
import "./App.css";
import { Pannellum, PannellumVideo } from "pannellum-react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Panorama from "./components/Panorama";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Panorama link="https://pacheca360.critecng.com/fotos/pacheca/Quinta_da_pacheca_0009_SuitePipa.jpg" />
        </Route>
        <Route exact path="/1">
          <Panorama link="https://pacheca360.critecng.com/fotos/pacheca/Suite01.png" />
        </Route>
        <Route exact path="/2">
          <Panorama link="https://pacheca360.critecng.com/fotos/pacheca/Suite02.png" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
